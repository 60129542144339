import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'layout',
    component: () => import('@/views/layout'),
    redirect: {
      name: 'home',
    },
    children: [
      {
        path: '/home',
        name: 'home',
        meta: {
          title: '首页',
        },
        component: () => import('@/views/home'),
      },
      {
        path: '/app',
        name: 'app',
        meta: {
          title: 'APP开发',
        },
        component: () => import('@/views/app'),
      },
      {
        path: '/minigrogram',
        name: 'minigrogram',
        meta: {
          title: '小程序开发',
        },
        component: () => import('@/views/minigrogram'),
      },
      {
        path: '/wechat',
        name: 'wechat',
        meta: {
          title: '微信开发',
        },
        component: () => import('@/views/wechat'),
      },
      {
        path: '/system',
        name: 'system',
        meta: {
          title: '系统开发',
        },
        component: () => import('@/views/system'),
      },
      {
        path: '/website',
        name: 'website',
        meta: {
          title: '网站开发',
        },
        component: () => import('@/views/website'),
      },
      {
        path: '/case',
        name: 'case',
        meta: {
          title: '案例展示',
        },
        component: () => import('@/views/case'),
      },
      {
        path: '/info',
        name: 'info',
        meta: {
          title: '行业资讯',
        },
        component: () => import('@/views/info'),
      },
      {
        path: '/about',
        name: 'about',
        meta: {
          title: '关于我们',
        },
        component: () => import('@/views/about'),
      },
      {
        path: '/caseDetail/:id',
        name: 'caseDetail',
        component: () => import('@/views/caseDetail'),
      },
      {
        path: '/infoDetail/:id',
        name: 'infoDetail',
        component: () => import('@/views/infoDetail'),
      },
    ],
  },

  {
    path: '*',
    meta: { title: '404' },
    component: () => import('@/views/404'),
  },
]

const router = new VueRouter({
  // mode: 'history',
  // base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
})

export default router
