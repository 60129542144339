import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'element-ui/lib/theme-chalk/index.css'
import './styles/index.scss'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import mLoading from '@/components/Loading'
import { IsPC } from '@/utils'
import {
  Container,
  Header,
  Aside,
  Main,
  Footer,
  Form,
  FormItem,
  Input,
  Button,
  Message,
  Loading,
} from 'element-ui'
Vue.config.productionTip = false
Vue.use(VueAwesomeSwiper)
Vue.use(Container)
  .use(Header)
  .use(Aside)
  .use(Main)
  .use(Footer)
  .use(Form)
  .use(FormItem)
  .use(Input)
  .use(Button)
  .use(Loading)
  .use(mLoading)
Vue.component(Message.name, Message)
Vue.prototype.$message = Message
Vue.use(Loading.directive)

Vue.prototype.$mloading = Loading.service
IsPC()
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
